/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
 import './src/styles/global.css';
 import './src/styles/style.css';
 import 'bootstrap/dist/css/bootstrap.min.css'
import FeatureProvider from './src/components/hooks/context'
export const wrapRootElement = FeatureProvider
