/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'
// creating global feature context
export const FeatureContext = createContext({
    activeFeature: 'Build your Workspaces',
    setActiveFeature: ()=>{}
})

// feature provider
const FeatureProvider = props =>{
    const [ activeFeature, setActiveFeature ] = useState('Build your Workspaces')
    const value = {activeFeature, setActiveFeature}
    return (
        <FeatureContext.Provider value={value}>{props.children}</FeatureContext.Provider>
    )
}
// export feature provider wrapper
export default ({element}) => <FeatureProvider>{element}</FeatureProvider>